import React from 'react';
import ConstructionSiteAction from '../../Actions/ConstructionSiteAction';
import CustomTranslation from '../Components/CustomTranslation';
import ProjectList from './Components/ProjectList';
import SwitchSelector from "react-switch-selector";
import TranslationAction from "../../Actions/TranslationAction";
import CustomContainer from "../Components/CustomContainer";
import CustomConfirm from "../Components/CustomConfirm";
import Loader from "../Components/Loader";
import {Button} from "react-bootstrap";
import UserAction from "../../Actions/UserAction";
import LicenseAction from "../../Actions/LicenseAction";
import {generatePath, useParams} from "react-router-dom";

class HomeScreen extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //set state
        this.state = {
           projects: null,
           active: true,
           showWarning: false,
           sortBy: localStorage.getItem('homeSort') || 'name'
        };
    }

    componentDidMount = () => {
        this.updateElements();
    }

    initCurrentUser = async () => {
        if (!this.state.currentUser) {
            //user and rights
            let currentUser = await UserAction.getCurrentUser();
            let userCompanyRights = await UserAction.getUserRightByCompanyId(currentUser.defaultCompany.id);

            //get licenses
            let licenses = await LicenseAction.getLicensesByUserAndCompany(currentUser.defaultCompany.id);

            //set in state
            this.setState({
                currentUser: currentUser,
                companyId: currentUser.defaultCompany.id,
                userCompanyRights: userCompanyRights,
                licenses: licenses
            });

            //return
            return currentUser;
        }

        //return
        return this.state.currentUser;
    }

    updateElements = async (active = true) => {

        //get current user
        let currentUser = await this.initCurrentUser();

        //get projects
        let projects = await ConstructionSiteAction.getConstructionSites(active, false, true, this.state.sortBy === 'location');

        //translate
        let translations = {};
        translations.Current = await TranslationAction.translate('KEY.Current');
        translations.Archived = await TranslationAction.translate('KEY.Archived');
        translations.Location = await TranslationAction.translate('KEY.Location');
        translations.Name = await TranslationAction.translate('KEY.Name');

        //set state
        this.setState({
            projects: projects,
            active: active,
            translations: translations
        });
    }

    render = () => {

        if (this.state.projects && this.state.translations && this.state.currentUser) {

            //set options for switch
            let options = [
                {
                    label: this.state.translations.Current,
                    value: true
                },
                {
                    label: this.state.translations.Archived,
                    value: false
                }
            ]

            //return
            return (
                <CustomContainer
                    footer={
                        (this.state.userCompanyRights && (this.state.userCompanyRights.managementRights || this.state.userCompanyRights.adminRights)) ?
                            <Button
                                block
                                variant={'primary'}
                                onClick={(event) => {
                                    if (this.state.licenses && (this.state.licenses.length > 0)) {
                                        window.location.href = generatePath('/create_construction_site');
                                    } else {
                                        this.setState({showWarning: true});
                                    }
                                }}
                            >
                                <CustomTranslation value={'KEY.New construction site'}/>
                            </Button>
                        :
                            null
                    }
                >
                    <div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >
                            <h1>
                                <CustomTranslation value={'KEY.Projects'} />
                            </h1>
                            <div className={'switch-selector'} style={{marginBottom: '1rem', display: 'flex', flexDirection: 'row'}}>
                                <span><CustomTranslation value={'KEY.Sort by'} />&nbsp;</span>
                                <div>
                                    <SwitchSelector
                                        options={[
                                            {
                                                label: this.state.translations.Name,
                                                value: 'name'
                                            },
                                            {
                                                label: this.state.translations.Location,
                                                value: 'location'
                                            }
                                        ]}
                                        selectedBackgroundColor={'#4f5b66'}
                                        fontColor={'#4f5b66'}
                                        selectedFontColor={'#fff'}
                                        backgroundColor={'#fff'}
                                        border={'solid 1px #4f5b66'}
                                        initialSelectedIndex={this.state.sortBy === 'location' ? 1 : 0}
                                        onChange={(value) => {
                                          this.setState({sortBy: value});
                                          localStorage.setItem('homeSort', value);
                                          this.updateElements(this.state.active);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={'switch-selector'} style={{marginBottom: '1rem'}}>
                                <SwitchSelector
                                    options={options}
                                    selectedBackgroundColor={'#4f5b66'}
                                    fontColor={'#4f5b66'}
                                    selectedFontColor={'#fff'}
                                    backgroundColor={'#fff'}
                                    border={'solid 1px #4f5b66'}
                                    initialSelectedIndex={0}
                                    onChange={(value) => {
                                        this.updateElements(value);
                                    }}
                                />
                            </div>
                        </div>
                        {/* List of projects */}
                        <ProjectList
                            data={this.state.projects}
                            active={this.state.active}
                        />
                    </div>
                    <CustomConfirm
                        show={this.state.showWarning}
                        title={<CustomTranslation value={'KEY.Warning'} />}
                        body={<CustomTranslation value={'KEY.Creating a new construction site is a paid function. You can create a subscription for free.'} />}
                        cancelLabel={<CustomTranslation value={'KEY.Close'} />}
                        onCancel={() => this.setState({showWarning: false})}
                        confirmLabel={<CustomTranslation value={'KEY.Create subscription'} />}
                        onConfirm={() => {
                            this.setState({showWarning: false});
                            window.open('https://www.cscapp.eu/tarieven', '_blank');
                        }}
                    />
                </CustomContainer>
            );
        } else {
            return (
                <CustomContainer>
                    <Loader />
                </CustomContainer>
            );
        }
    }
}

//export
export default function (props) {
    const params = useParams();
    return <HomeScreen {...props} params={params} />;
}