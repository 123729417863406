import React from 'react';
import CustomContainer from "../Components/CustomContainer";
import {generatePath, useParams} from "react-router-dom";
import ConstructionSiteAction from "../../Actions/ConstructionSiteAction";
import Project from "../Home/Components/Project";
import Loader from "../Components/Loader";
import RiskAnalysisAction from "../../Actions/RiskAnalysisAction";
import {Breadcrumb, Button, Modal} from "react-bootstrap";
import CustomTranslation from "../Components/CustomTranslation";
import CustomBorderedButton from "../Components/CustomBorderedButton";
import G from "../../Actions/GeneralAction";
import CustomInput from "../Components/CustomInput";
import TranslationAction from "../../Actions/TranslationAction";
import Uuid from 'uuid/v1';
import UserAction from "../../Actions/UserAction";
import RiskAnalysisFileButton from "./Components/RiskAnalysisFileButton";
import fileDownload from "js-file-download";
import Config from "../../Config.json";

//define class
class RiskAnalysisScreen extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //state
        this.state = {
            modalVisible: false,
            renderId: Uuid()
        }
    }

    //on mount
    componentDidMount = () => {
        this.updateElements();
    }

    //get data
    updateElements = async () => {

        //get risk analysis
        let ra = await RiskAnalysisAction.get(this.props.params.id);

        //get project
        let cs = await ConstructionSiteAction.getConstructionSite(ra.constructionSite.id);

        //translate title
        let title = await TranslationAction.translateArray(ra.title);

        //get rights
        let userConstructionSiteRights = await UserAction.getUserRightByConstructionSiteId(cs.id);

        //set state
        this.setState({
            data: {
                constructionSite: cs,
                riskAnalysis: ra,
                rights: userConstructionSiteRights
            },
            title: title,
            id: ra.id
        });
    }

    //set new title
    updateTitle = async () => {

        //get current language
        let language = await TranslationAction.currentLanguage();

        //get result
        let result = await RiskAnalysisAction.update(this.state, language);

        //set in data
        this.setState({
            data: {...this.state.data, ...{riskAnalysis: result}},
            modalVisible: false,
            renderId: Uuid()
        });
    }

    //render view
    render() {

        if (this.state.data) {
            return (
                <CustomContainer>
                    <div key={this.state.renderId}>
                        {/* Project header */}
                        <div style={{position: 'sticky', top: '2rem', zIndex: 9}}>
                            <Project
                                float
                                data={this.state.data.constructionSite}
                            />
                        </div>

                        {/* Breadcrumb */}
                        <Breadcrumb className={'vertical-margin-2'}>
                            <Breadcrumb.Item
                                href={generatePath('/')}
                            >
                                <CustomTranslation value={'KEY.Home'} />
                            </Breadcrumb.Item>
                            <Breadcrumb.Item
                                href={generatePath('/construction_site/:id', {id: this.state.data.constructionSite.id})}
                            >
                                {this.state.data.constructionSite.name}
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                <CustomTranslation value={this.state.data.riskAnalysis.title} />
                            </Breadcrumb.Item>
                        </Breadcrumb>

                        {/* Title */}
                        <div>
                            <h1>
                                <CustomTranslation value={'KEY.Detail Risk Analysis'}/>
                            </h1>
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                marginLeft: '-3px',
                                marginRight: '-3px'
                            }}
                        >
                            {/* Change title */}
                            {this.state.data.rights.managementRights &&
                                <CustomBorderedButton
                                    className={'standard-margin-3 vertical-margin-3'}
                                    style={{flex: 1, flexBasis: 250}}
                                    title={<CustomTranslation value={'KEY.Edit title'}/>}
                                    icon={'las la-edit'}
                                    onClick={() => this.setState({modalVisible: true})}
                                />
                            }

                            {/* Risk analysis edit */}
                            {this.state.data.rights.managementRights &&
                                <div
                                    className={'rounded standard-padding vertical-padding touchable standard-margin-3 vertical-margin-3 ' + (
                                        this.state.data.riskAnalysis.isFinished ? 'bg-success' : 'bg-primary'
                                    )}
                                    style={{
                                        flex: 1,
                                        flexBasis: 250,
                                        display: 'flex',
                                        flexDirection: 'row'
                                    }}
                                    onClick={() => {
                                        window.location.href = generatePath('/risk_analysis/:id/edit', {id: this.state.data.riskAnalysis.id});
                                    }}
                                >
                                    <div>
                                        <div
                                            className={'bg-white'}
                                            style={{
                                                height: '1.5rem',
                                                width: '1.5rem',
                                                borderRadius: '50%',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <i
                                                className={'las la-cog ' + (
                                                    this.state.data.riskAnalysis.isFinished ? 'text-success' : 'text-primary'
                                                )}
                                                style={{
                                                    fontSize: '1rem'
                                                }}
                                            >
                                            </i>
                                        </div>
                                    </div>
                                    <div
                                        className={'standard-margin-2'}
                                        style={{
                                            marginRight: 0
                                        }}
                                    >
                                        <h3 className={'text-white'}>
                                            <CustomTranslation value={this.state.data.riskAnalysis.title}/>
                                        </h3>
                                        <h5 className={'unbold text-white'}>
                                            {
                                                (this.state.data.riskAnalysis.startedBy.firstName || this.state.data.riskAnalysis.startedBy.lastName)
                                                    ?
                                                    (this.state.data.riskAnalysis.startedBy.firstName || '') + ' ' + (this.state.data.riskAnalysis.startedBy.lastName || '')
                                                    :
                                                    (this.state.data.riskAnalysis.startedBy.email || '')
                                            } [{G.formatDate(this.state.data.riskAnalysis.createdAt)}]
                                        </h5>
                                    </div>
                                </div>
                            }
                        </div>

                        {this.state.data.riskAnalysis.isFinished &&
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    flexWrap: 'wrap',
                                    marginLeft: '-3px',
                                    marginRight: '-3px'
                                }}
                            >
                                {/* Full risk analysis */}
                                <RiskAnalysisFileButton
                                    style={{flex: 1, flexBasis: 250}}
                                    className={'standard-margin-3 vertical-margin-3'}
                                    title={<CustomTranslation value={'KEY.Full risk analysis'}/>}
                                    onClick={() => {
                                        //default file name
                                        let fileName = 'document.pdf';
                                        fetch(Config.BASE_URL + '/en/general/document/tra/full/' + this.state.data.riskAnalysis.id + '/1')
                                            .then(res => {
                                                fileName = res.headers.get('content-disposition');
                                                let parts = fileName.split(';');
                                                fileName = parts[1].split('=')[1];
                                                return res.blob();
                                            })
                                            .then(res => {
                                                fileName = fileName.replace(/['"]+/g, '');
                                                fileDownload(res, fileName, 'application/pdf');
                                            });
                                    }}
                                    onMailPress={(this.state.data.rights.managementRights) ? () => {
                                        window.location.href = generatePath('/risk_analysis/:id/email/:limited', {id: this.state.data.riskAnalysis.id, limited: 0});
                                    } : null}
                                />

                                {/* Limited risk analysis */}
                                <RiskAnalysisFileButton
                                    style={{flex: 1, flexBasis: 250}}
                                    className={'standard-margin-3 vertical-margin-3'}
                                    title={<CustomTranslation value={'KEY.Limited risk analysis'}/>}
                                    onClick={() => {
                                        //default file name
                                        let fileName = 'document.pdf';
                                        fetch(Config.BASE_URL + '/en/general/document/tra/limited/' + this.state.data.riskAnalysis.id + '/1')
                                            .then(res => {
                                                fileName = res.headers.get('content-disposition');
                                                let parts = fileName.split(';');
                                                fileName = parts[1].split('=')[1];
                                                return res.blob();
                                            })
                                            .then(res => {
                                                fileName = fileName.replace(/['"]+/g, '');
                                                fileDownload(res, fileName, 'application/pdf');
                                            });
                                    }}
                                    onMailPress={(this.state.data.rights.managementRights) ? () => {
                                        window.location.href = generatePath('/risk_analysis/:id/email/:limited', {id: this.state.data.riskAnalysis.id, limited: 1});
                                    } : null}
                                />
                            </div>
                        }

                        {/* Title change modal */}
                        <Modal
                            show={this.state.modalVisible}
                            onHide={null}
                            backdrop={'static'}
                            keyboard={false}
                        >
                            <Modal.Body>
                                <div>
                                    <CustomInput
                                        label={<CustomTranslation value={'KEY.Title'} />}
                                        iconName={'las la-quote-left'}
                                        value={this.state.title}
                                        onChangeText={(text) => this.setState({title: text})}
                                    />
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        width: '100%'
                                    }}
                                >
                                    <div className={'standard-margin-3'} style={{flex: 1, marginLeft: 0}}>
                                        <Button
                                            block
                                            variant={'dark'}
                                            onClick={() => this.setState({modalVisible: false})}
                                        >
                                            <CustomTranslation value={'KEY.Cancel'} />
                                        </Button>
                                    </div>
                                    <div className={'standard-margin-3'} style={{flex: 1, marginRight: 0}}>
                                        <Button
                                            block
                                            variant={'success'}
                                            onClick={() => this.updateTitle()}
                                        >
                                            <CustomTranslation value={'KEY.Save'} />
                                        </Button>
                                    </div>
                                </div>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </CustomContainer>
            );
        } else {
            return (
                <CustomContainer>
                    <Loader />
                </CustomContainer>
            );
        }
    }
}

//export
export default function (props) {
    const params = useParams();
    return <RiskAnalysisScreen {...props} params={params} />;
}
