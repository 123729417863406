import React from "react";
import {generatePath, useParams} from "react-router-dom";
import CustomContainer from "../Components/CustomContainer";
import {Breadcrumb, Button} from "react-bootstrap";
import CustomTranslation from "../Components/CustomTranslation";
import CustomInput from "../Components/CustomInput";
import CustomSelect from "../Components/CustomSelect";
import T from "../../Actions/TranslationAction";
import Loader from "../Components/Loader";
import UserAction from "../../Actions/UserAction";
import CustomMultiListInput from "../Components/CustomMultiListInput";
import ConstructionSiteAction from "../../Actions/ConstructionSiteAction";
import CountryAction from "../../Actions/CountryAction";
import CompanyAction from "../../Actions/CompanyAction";
import Uuid from "uuid/v1";

class CreateConstructionSiteScreen extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //set state
        this.state = {
            name: '',
            addressCompany: '',
            street: '',
            number: '',
            zip: '',
            city: '',
            countryUuid: Uuid(),
            country: '',
            company: '',
            countries: [],
            companies: [],
            projectManager: null,
            workManager: null,
            users: [],
            contactPersons: []
        }
    }

    componentDidMount = () => {
        this.updateElements();
    }


    //update data
    updateElements = async () => {

        //array for requests
        let requests = [];

        //push needed requests
        //get countries
        requests.push(CountryAction.getCountries());
        //get companies
        requests.push(CompanyAction.getCompanies());

        //wait for result
        let results = await Promise.all(requests);

        //set results
        let countries = [];
        for (let index in results[0]) {
            let element = results[0][index];
            let label = await T.translateArray(element.name);
            countries.push({'label': label, 'value': element['@id'], 'code': element['countryCode'] });
        }

        let companies = [];
        let company = null;
        results[1].forEach(element => {
            companies.push({'label': element.name, 'value': element['@id'], 'id': element.id});

            // Auto select first company (if no autoselect, reenable dropdown)
            if(!company){
                company = element.id;
            }
        });

        //set state
        this.setState({
            country: countries[0],
            countries: countries,
            companies: companies,
            company: company,
        });

        //set users based on company
        //this.companyChange(companies[0].value, companies);
        this.companyChange(company, companies);
    }
    
    //update country
    updateCountry = async (value) => {
        this.state.countries.forEach(element => {
            if (element.value === value) {
                this.setState({
                    country: element,
                    countryUuid: Uuid()
                });
            }
        });
    }

    //check if everything is filled in
    isReady = () => {
        if (this.state.name && this.state.street && this.state.number && this.state.zip && this.state.city) {
            return true;
        }
        return false;
    }

    //check if user list needs updating
    companyChange = async (company, companies = null) => {

        //check if companies are provided
        if (companies === null) {
            companies = this.state.companies;
        }

        //get company id
        let companyId = null;
        let companyItem = null;
        companies.forEach((element) => {
            if (element.value === company) {
                companyId = element.id;
                companyItem = element;
            }
        });

        //check if OK
        if (companyId === null || companyItem === null) {
            companyId = companies[0].id;
            companyItem = companies[0];
        }

        //get new users
        let users = await UserAction.getUserListCompany(companyId);

        //make elements
        let userElements = [];
        //add empty option
        let label = await T.translate('KEY.Empty');
        userElements.push({'label': label, 'value': null});
        //loop
        users.forEach(element => {
            let label = null;
            if ((!element.firstName) || (!element.lastName)) {
                label = element.email;
            } else {
                label = (element.firstName || '') + ' ' + (element.lastName || '');
            }
            userElements.push({'label': label, 'value': element.email});
        });

        //set current user if possible
        let user = users[0];

        //get user
        let dbUser = await UserAction.getCurrentUser();

        //search
        userElements.forEach(element => {
            if (element.value === dbUser.email) {
                user = element;
            }
        });

        //set new sate
        this.setState({
            company: companyItem,
            projectManager: user,
            workManager: user,
            users: userElements
        });
    }

    //create construction site
    createConstructionSite = async () => {

        //create
        let result = await ConstructionSiteAction.create(this.state);

        //check if result is valid
        if (result && result.id) {

            //navigate
            window.location.href = generatePath('/construction_site/:id', {'id': result.id});
        }
    }

    //render view
    render = () => {
        if ((this.state.companies.length > 0) && (this.state.countries.length > 0) && (this.state.users.length > 0)) {
            return (
                <CustomContainer
                    footer={
                        <Button
                            block
                            variant={'primary'}
                            disabled={!this.isReady()}
                            onClick={() => {
                                this.createConstructionSite();
                            }}
                        >
                            <CustomTranslation value={'KEY.Create new construction site'} />
                        </Button>
                    }
                >
                    <div>
                        {/* Breadcrumb */}
                        <Breadcrumb className={'vertical-margin-2'} style={{marginTop: 0}}>
                            <Breadcrumb.Item
                                href={generatePath('/')}
                            >
                                <CustomTranslation value={'KEY.Home'}/>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                <CustomTranslation value={'KEY.New construction site'}/>
                            </Breadcrumb.Item>
                        </Breadcrumb>

                        {/* Title */}
                        <div>
                            <h1>
                                <CustomTranslation value={'KEY.New construction site'}/>
                            </h1>
                        </div>

                        {/* Form */}
                        <div>
                            <div className={'vertical-margin-2'}>
                                <CustomInput
                                    iconName={'las la-quote-left'}
                                    label={<CustomTranslation value={'KEY.Construction site name'}/>}
                                    onChangeText={(text) => this.setState({name: text})}
                                />
                            </div>
                        </div>
                        <div className={'vertical-margin-2'}>
                            <CustomInput
                                iconName={'las la-industry'}
                                label={<CustomTranslation value={'KEY.Company name'}/>}
                                onChangeText={(text) => this.setState({addressCompany: text})}
                            />
                        </div>
                        <div
                            className={'vertical-margin-2'}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginLeft: '-3px',
                                marginRight: '-3px'
                            }}
                        >
                            <div
                                style={{
                                    flexGrow: 1,
                                    flexShrink: 1
                                }}
                                className={'standard-margin-3'}
                            >
                                <CustomInput
                                    iconName={'las la-home'}
                                    label={<CustomTranslation value={'KEY.Street project location'}/>}
                                    onChangeText={(text) => this.setState({street: text})}
                                />
                            </div>
                            <div
                                className={'standard-margin-3'}
                            >
                                <CustomInput
                                    iconName={'las la-home'}
                                    label={<CustomTranslation value={'KEY.Number'}/>}
                                    onChangeText={(text) => this.setState({number: text})}
                                />
                            </div>
                        </div>
                        <div
                            className={'vertical-margin-2'}
                            style={{
                                display: 'flex',
                                flexDirection: 'row'
                            }}
                        >
                            <div
                                className={'standard-margin-3'}
                            >
                                <CustomInput
                                    iconName={'las la-city'}
                                    label={<CustomTranslation value={'KEY.Zip'}/>}
                                    onChangeText={(text) => this.setState({zip: text})}
                                />
                            </div>
                            <div
                                className={'standard-margin-3'}
                                style={{
                                    flexGrow: 1,
                                    flexShrink: 1
                                }}
                            >
                                <CustomInput
                                    iconName={'las la-city'}
                                    label={<CustomTranslation value={'KEY.City'}/>}
                                    onChangeText={(text) => this.setState({city: text})}
                                />
                            </div>
                        </div>
                        <div className={'vertical-margin-2'}>
                            <CustomSelect
                                iconName={'las la-globe'}
                                label={<CustomTranslation value={'KEY.Country'}/>}
                                options={this.state.countries}
                                selected={this.state.country}
                                onSelect={(value, label) => {
                                    this.updateCountry(value);
                                }}
                            />
                        </div>
                        <div className={'vertical-margin-2'}>
                            <CustomSelect
                                key={this.state.countryUuid + '_1'}
                                iconName={'las la-user'}
                                label={
                                    this.state.country.code.toUpperCase() === 'NL' ?
                                    <CustomTranslation value={'KEY.Projectmanager'} /> :
                                    <CustomTranslation value={'KEY.Project manager'}/>
                                }
                                options={this.state.users}
                                selected={this.state.projectManager}
                                onSelect={(value, label) => {this.setState({'projectManager': {label: label, value: value}})}}
                            />
                        </div>
                        <div className={'vertical-margin-2'}>
                            <CustomSelect
                                key={this.state.countryUuid + '_2'}
                                iconName={'las la-user'}
                                label={
                                    this.state.country.code.toUpperCase() === 'NL' ?
                                    <CustomTranslation value={'KEY.Hoofduitvoerder'} /> :
                                    <CustomTranslation value={'KEY.Work manager'}/>
                                }
                                options={this.state.users}
                                selected={this.state.workManager}
                                onSelect={(value, label) => {this.setState({'workManager': {value: value, label: label}})}}
                            />
                        </div>
                        <div className={'vertical-margin-2'}>
                            <CustomMultiListInput
                                iconName={'las la-user'}
                                label={<CustomTranslation value={'KEY.Company Contact Persons'} />}
                                fields={{
                                    name: <CustomTranslation value={'KEY.Name'} />,
                                    email: <CustomTranslation value={'KEY.Email'} />,
                                    phone: <CustomTranslation value={'KEY.Phone'} />,
                                    function: <CustomTranslation value={'KEY.Function'} />
                                }}
                                values={this.state.contactPersons}
                                onChangeItems={(value) => {this.setState({'contactPersons': value})}}
                            />
                        </div>
                    </div>
                </CustomContainer>
            );
        } else {
            return (
                <CustomContainer>
                    <Loader />
                </CustomContainer>
            );
        }
    }
}

//export
export default function (props) {
    const params = useParams();
    return <CreateConstructionSiteScreen {...props} params={params} />;
}