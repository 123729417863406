import React from "react";
import CustomTranslation from "../../Components/CustomTranslation";
import Loader from "../../Components/Loader";
import LicenseAction from "../../../Actions/LicenseAction";
import GeneralAction from "../../../Actions/GeneralAction";
import CompanyLicenseEditModal from "./Components/CompanyLicenseEditModal";

class CompanyLicenseOverviewTab extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //set state
        this.state = {

        };
    }

    //on initial
    componentDidMount = () => {
        //update elements
        this.updateElements();
    }

    //update elements
    updateElements = async () => {

        //get licenses
        let licenseItems = [];
        let licenses = await LicenseAction.getLicensesByCompany(this.props.defaultCompany.id);

        //loop and create license JSX
        licenses.forEach((license, id) => {
            licenseItems.push(
                <div key={id} className={'vertical-padding-3'}>
                    <div
                        className={'bordered rounded touchable'}
                        style={{
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                        onClick={(event) => {
                            this.createModal(license);
                        }}
                    >
                        <div
                            className={(license.user ? 'bg-primary' : 'bg-success') + ' rounded'}
                            style={{
                                minWidth: 10,
                                maxWidth: 10,
                                alignSelf: 'stretch'
                            }}
                        />
                        <div
                            className={'vertical-padding-2'}
                            style={{
                                display: 'flex',
                                flex: 1,
                                flexDirection: 'row',
                                alignItems: 'center'
                            }}
                        >
                            <h5
                                className={'standard-margin'}
                                style={{display: 'flex', flexGrow: 1, flexShrink: 1}}
                            >
                                {license.code ? license.code : <CustomTranslation value={'KEY.No code'} />}
                            </h5>
                            <p className={'label unbold standard-padding-2'}>
                                {GeneralAction.formatDate(license.startDate)}-{GeneralAction.formatDate(license.endDate)}
                            </p>
                        </div>
                    </div>
                </div>
            );
        });

        //set state
        this.setState({
            licenses: licenseItems
        });
    }

    //create modal
    createModal = async (license) => {
        //create modal jsx
        let modal = (
            <CompanyLicenseEditModal
                license={license}
                company={this.props.defaultCompany}
                onCancel={() => {
                    this.setState({modal: null});
                }}
                onConfirm={() => {
                    this.setState({modal: null}, () => {
                        this.updateElements();
                    });
                }}
            />
        );

        //set modal in state
        this.setState({
            modal: modal
        });
    }

    //render
    render = () => {

        //if loaded
        if (Object.keys(this.state).length > 0) {
            return (
                <div style={this.props.style} className={this.props.className} key={this.props.key}>
                    {/* Title */}
                    <div className={'vertical-margin-2'} style={{marginTop: 0}}>
                        <h2 className={'text-left'}><CustomTranslation value={'KEY.Company Licences'}/></h2>
                    </div>
                    {/* Content */}
                    <div>
                        {/* Indicators */}
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}} >
                            <div style={{width: 15, height: 15}} className={'bg-success rounded'}/>
                            <p style={{marginBottom: 0, paddingBottom: 0}} className={'standard-margin-2 label unbold'}><CustomTranslation
                                value={'KEY.Available'}/></p>
                            <div style={{width: 15, height: 15}} className={'bg-primary rounded'}/>
                            <p style={{marginBottom: 0, paddingBottom: 0}} className={'standard-margin-2 label unbold'}><CustomTranslation
                                value={'KEY.In use'}/></p>
                        </div>
                        {this.state.licenses}
                    </div>
                    {/* Modal */}
                    {this.state.modal}
                </div>
            );
        } else {
            return (
                <Loader />
            );
        }
    }
}

//export
export default CompanyLicenseOverviewTab;