//imports
import APIAction from './APIAction';
import CustomTranslation from "../Views/Components/CustomTranslation";
import T from "../Actions/TranslationAction";
import {toast} from "react-hot-toast";

//define class
let ConstructionSiteAction = {

    /**
     * Get constuction sites, used on home page.
     */
    getConstructionSites: async function(active = true, all = false, assignmentCount = true, locationBased = true) {

        //get coordinates if possible
        let coordinates = null;
        if (!all && locationBased) {
            coordinates = await this.getLocation();
        }
        
        //init data
        let data = {
            assignmentCount: assignmentCount
        };
        data['active'] = active;
        if (coordinates) data['geoCoordinates'] = coordinates;
        if (all) data['all'] = true;

        //call api and get result
        let result = await APIAction.request('/construction_sites', 'GET', true, data);

        //return result
        return result;
    },

    /**
     * Get construction site based on id.
     * @param {*} id
     * @param assignmentCount
     */
    getConstructionSite: async function(id, assignmentCount = false) {

        //get coordinates if possible
        let coordinates = await this.getLocation();
        
        //set data
        let data = {};
        if (assignmentCount) data.assignmentCount = true;
        if (coordinates) data['geoCoordinates'] = coordinates;

        //call api and get result
        let result = await APIAction.request('/construction_sites/' + id, 'GET', true, data);

        //return result
        return result;

    },

    /**
     * Makes promise for getting location
     */
    getLocation: async function() {
        if ("geolocation" in navigator) {
            try {
                //define promise
                let promise = new Promise((resolve) => {

                    //get location
                    navigator.geolocation.getCurrentPosition((position) => {
                       resolve({
                           "lat": Math.round(position.coords.latitude * 100000) / 100000,
                           "long": Math.round(position.coords.longitude * 100000) / 100000
                       });
                    }, (error) => {
                        resolve(null);
                    });
                });

                //timeout for race
                let timeout = new Promise((resolve) => {
                    setTimeout(() => {
                        resolve(null);
                    }, 500);
                });

                //return promise
                return Promise.race([promise, timeout]);
            } catch (e) {}
        }

        //return fail
        return null;
    },

    /**
     * Create new construction site based on input
     *
     * @param {*} input
     */
    create: async function(input) {
        
        //gather data for request
        let data = {
            name: input.name,
            addressCompany: input.addressCompany,
            street: input.street,
            number: input.number,
            zip: input.zip,
            city: input.city,
            country: {
                id: input.country.value
            },
            company: {
                id: input.company.value
            },
            custom: {
                autoCreateTra: false,
                workManagerEmail: input.workManager.value,
                projectManagerEmail: input.projectManager.value,
                contactPersons: input.contactPersons
            }
        };

        //call api
        let result = await APIAction.request('/construction_sites', 'POST', true, data);

        //return
        return result;
    },

    /**
     * Edit construction site based on input
     *
     * @param {*} input
     */
    edit: async function(input) {
        
        //gather data for request
        let data = {};
        //these are fields that always have a content when the construction site form is filled in.
        //the value of the field is stored in value attribute
        if (!input.hospital) {
            data = {
                name: input.name,
                addressCompany: input.addressCompany,
                street: input.street,
                number: input.number,
                zip: input.zip,
                city: input.city,
                country:  input.countryId,
                custom: {
                    workManagerEmail: input.workManager.value,
                    projectManagerEmail: input.projectManager.value,
                    contactPersons: input.contactPersons
                }
            };

            //set workmanager if appropriate
            if (input.workManager && input.workManager.value) {
                data['custom']['workManagerEmail'] = input.workManager.value;
            }
            //set projectmanager if appropriate
            if (input.projectManager && input.projectManager.value) {
                data['custom']['projectManagerEmail'] = input.projectManager.value;
            }
        }

        //check if hospital is filled in
        if (input.hospital) {
            data = {};
            data.hospital = {
                name: input.hospital.name,
                street: input.hospital.street,
                number: input.hospital.number,
                zip: input.hospital.zip,
                city: input.hospital.city,
                country: {
                    id: input.hospital.country.value
                },
                phone: input.hospital.phone
            };
        }

        //call api
        let result = await APIAction.request('/construction_sites/' + input.constructionSiteId, 'PATCH', true, data);

        //show toast
        if (result && result.id && input.hospital) {
            toast.success(<CustomTranslation value={'KEY.Hospital saved'} />);
        } else if (result && result.id) {
            toast.success(<CustomTranslation value={'KEY.Construction site saved'} />);
        } else {
            toast.error(<CustomTranslation value={'KEY.Save failed'} />);
        }

        //return
        return result;
    },

    /**
     * Edit construction site based on formdata
     *
     * @param id
     * @param data
     */
    editConstructionSite: async function(id, data) {
        //call api
        let result = await APIAction.request('/construction_sites/' + id, 'PATCH', true, data);

        //show toast
        if (result && result.id) {
            toast.success(<CustomTranslation value={'KEY.Construction site saved'} />);
        } else {
            toast.error(<CustomTranslation value={'KEY.Save failed'} />);
        }

        //return
        return result;
    },

    /**
     * Get right for construction site
     *
     * @param {*} constructionSiteId
     */
    getRights: async function(constructionSiteId) {

        //call api
        let result = await APIAction.request('/user_construction_site_rights', 'GET', true, {
            constructionSiteId: constructionSiteId
        });

        //return
        return result;
    },

    /**
     * Update a Construction site right
     *
     * @param {*} right
     */
    updateRights: async function(right) {

        //call api
        let result = await APIAction.request('/user_construction_site_rights/' + right.id, 'PATCH', true, right);

        //return
        return result;
    },

    /**
     * Create a Construction Site Right
     *
     * @param {*} right
     */
    createRights: async function(right) {

        //call api
        let result = await APIAction.request('/user_construction_site_rights', 'POST', true, right);

        //return
        return result;
    },

    /**
     * Create a first aid kit
     *
     * @param {*} firstAidKit
     */
    editFirstAidKit: async function(firstAidKit) {

        //result var
        let result = null;

        //check if create or edit
        //create
        if (firstAidKit.id === null || firstAidKit.id === undefined) {
            delete firstAidKit.id;
            //call api
            result = await APIAction.request('/first_aid_kits', 'POST', true, firstAidKit);
        //edit
        } else {
            //call api
            result = await APIAction.request('/first_aid_kits/' + firstAidKit.id, 'PATCH', true, firstAidKit);
        }

        //return
        return result;
    },

    /**
     * Delete first aid kit
     *
     * @param {*} firstAidKit
     */
    deleteFirstAidKit: async function(firstAidKit) {
        //call api
        let result = await APIAction.request('/first_aid_kits/' + firstAidKit.id, 'DELETE', true);

        //return
        return result;
    },

    /**
     * Delete Construction Site
     * (Set active = false)
     *
     * @param constructionSiteId
     */
    delete: async function(constructionSiteId) {
        //call api
        let result = await APIAction.request('/construction_sites/' + constructionSiteId, 'PATCH', true, {
            active: false
        });

        //return
        return result;
    },

    /**
     * Send Accessibility as email
     *
     * @param {*} input
     */
    sendAccessibility: async function(input) {

        //set data object
        let data = {
            emails: input.email,
            construction_site_id: input.cs,
            email_subject: input.title,
            email_body: input.message
        };

        //call
        let result = await APIAction.request('/send_accessibility', 'POST', true, data);

        //init translation
        T.init();

        //check what toast to send
        if (result.status === 'success') {
            //set toast
            let toastMessage = await T.translate('KEY.Message send.');
            toast.success(toastMessage);
        } else {
            //set toast
            let toastMessage = await T.translate('KEY.Message failed.');
            toast.error(toastMessage);
        }

        //signal end
        return true;
    },
};

//export
export default ConstructionSiteAction;