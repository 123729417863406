import React from "react";
import {generatePath, useParams} from "react-router-dom";
import CustomContainer from "../../Components/CustomContainer";
import Loader from "../../Components/Loader";
import UserAction from "../../../Actions/UserAction";
import {Breadcrumb, Button, Modal, Nav, Tab} from "react-bootstrap";
import CustomTranslation from "../../Components/CustomTranslation";
import LicenseAction from "../../../Actions/LicenseAction";
import ConstructionSiteAction from "../../../Actions/ConstructionSiteAction";
import AnalyticsLog from "../../../Firebase/AnalyticsLog";
import CustomInfoButton from "../../Components/CustomInfoButton";
import CustomConfirm from "../../Components/CustomConfirm";
import {toast} from "react-hot-toast";
import EditConstructionSiteTab from "./EditConstructionSiteTab";
import EditConstructionSiteProjectInfoTab from "./EditConstructionSiteProjectInfoTab";
import EditConstructionSiteAccessibilityTab from "./EditConstructionSiteAccessibilityTab";
import EditHospitalTab from "./EditHospitalTab";
import EditFirstAidKitTab from "./EditFirstAidKitTab";
import EditRightTab from "./EditRightTab";

class EditConstructionSiteScreen extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //set state
        this.state = {
            infoModal: false,
            deleteModal: false,
        };
    }

    //on mount
    componentDidMount = () => {
        this.updateElements();
    }

    //update elements
    updateElements = async () => {

        //get construction site
        let constructionSite = await ConstructionSiteAction.getConstructionSite(this.props.params.id);

        //get rights
        let userConstructionSiteRights = await UserAction.getUserRightByConstructionSiteId(constructionSite.id);

        //get licenses
        let licenses = await LicenseAction.getLicensesByUserAndCompany(constructionSite.company.id);

        //check if rights are correct
        if (!(
            (licenses && licenses.length > 0) &&
            (userConstructionSiteRights && userConstructionSiteRights.managementRights)
        )) {
            //if not, send back
            window.location.href = generatePath('/construction_site/:id', {id: constructionSite.id});
        }

        //set state
        this.setState({
            data: {
                constructionSite: constructionSite,
            },
            userConstructionSiteRights: userConstructionSiteRights,
            licenses: licenses
        });
    }

    //delete construction site
    deleteConstructionSite = async () => {

        //hide modal
        this.setState({deleteModal: false});

        //delete construction site
        let result = await ConstructionSiteAction.delete(this.state.data.constructionSite.id);

        //if result is OK
        if (result && (result.active === false)) {

            //show toast
            toast.success(<CustomTranslation value={'KEY.Construction site removed!'} />);

            //navigate to home screen
            setTimeout(() => {
                window.location.href = generatePath('/');
            }, 1000);
        }
        //if result is NOK
        else {
            //show toast
            toast.error(<CustomTranslation value={'KEY.Construction cannot be removed.'} />);
        }
    }

    render = () => {

        if (this.state.data) {
            return (
                <CustomContainer>
                    <div>
                        {/* Breadcrumb */}
                        <Breadcrumb className={'vertical-margin-2'} style={{marginTop: 0}}>
                            <Breadcrumb.Item
                                href={generatePath('/')}
                            >
                                <CustomTranslation value={'KEY.Home'}/>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item
                                href={generatePath('/construction_site/:id', {id: this.state.data.constructionSite.id})}
                            >
                                {this.state.data.constructionSite.name}
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                <CustomTranslation value={'KEY.Construction site details'}/>
                            </Breadcrumb.Item>
                        </Breadcrumb>

                        {/* Title */}
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between'
                            }}
                        >
                            <div>
                                <h1>
                                    <CustomTranslation value={'KEY.Construction site details'}/>
                                </h1>
                            </div>
                            <div>
                                <CustomInfoButton
                                    onClick={() => this.setState({infoModal: true})}
                                />
                            </div>
                        </div>

                        {/* Tabs container */}
                        <div>
                            <Tab.Container defaultActiveKey={'construction_site'} mountOnEnter={true} unmountOnExit={false}>
                                {/* Flex container */}
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flexWrap: 'wrap'
                                    }}
                                >
                                    <div
                                        className={'standard-margin vertical-margin-2'}
                                        style={{
                                            marginLeft: 0,
                                            marginTop: 0,
                                        }}
                                    >
                                        {/* General settings */}
                                        <div className={'bordered rounded vertical-margin-2'} style={{marginTop: 0}}>
                                            <Nav variant={'pills'} className={'flex-column'}>
                                                <Nav.Item>
                                                    <Nav.Link eventKey={'construction_site'}>
                                                        <i className={'las la-industry'} /> <CustomTranslation value={'KEY.Edit construction site'} />
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey={'project_info'}>
                                                        <i className={'las la-info-circle'} /> <CustomTranslation value={'KEY.Project Info'} />
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey={'project_accessibility'}>
                                                        <i className={'las la-clipboard-list'} /> {
                                                            this.state.data.constructionSite.country.countryCode.toUpperCase() === 'NL' ?
                                                            <CustomTranslation value={'KEY.Projectlocatie voorschriften'}/> :
                                                            <CustomTranslation value={'KEY.Accessibility'}/>
                                                        }
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey={'hospital'}>
                                                        <i className={'las la-hospital'} /> <CustomTranslation value={'KEY.Nearest hospital'} />
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey={'first_aid'}>
                                                        <i className={'las la-first-aid'} /> <CustomTranslation value={'KEY.First aid kits'} />
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey={'right'}>
                                                        <i className={'las la-user'} /> <CustomTranslation value={'KEY.Users construction site'} />
                                                    </Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </div>
                                        {/* Delete */}
                                        <Button
                                            block
                                            variant={'danger'}
                                            onClick={() => this.setState({deleteModal: true})}
                                        >
                                            <i className={'las la-trash'} /> <CustomTranslation value={'KEY.Archive construction site'} />
                                        </Button>
                                    </div>
                                    {/* Content container */}
                                    <div
                                        style={{
                                            flexGrow: 1,
                                            flexShrink: 1
                                        }}
                                    >
                                        <Tab.Content>
                                            <Tab.Pane eventKey={'construction_site'}>
                                                <AnalyticsLog screen={'EditConstructionSite'} />
                                                <EditConstructionSiteTab constructionSite={this.state.data.constructionSite} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey={'project_info'}>
                                                <AnalyticsLog screen={'EditConstructionSiteProjectInfo'} />
                                                <EditConstructionSiteProjectInfoTab constructionSite={this.state.data.constructionSite} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey={'project_accessibility'}>
                                                <AnalyticsLog screen={'EditConstructionSiteAccessibility'} />
                                                <EditConstructionSiteAccessibilityTab constructionSite={this.state.data.constructionSite} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey={'hospital'}>
                                                <AnalyticsLog screen={'EditHospital'} />
                                                <EditHospitalTab constructionSite={this.state.data.constructionSite} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey={'first_aid'}>
                                                <AnalyticsLog screen={'EditFirstAidKit'} />
                                                <EditFirstAidKitTab constructionSite={this.state.data.constructionSite} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey={'right'}>
                                                <AnalyticsLog screen={'EditRight'} />
                                                <EditRightTab constructionSite={this.state.data.constructionSite} />
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </div>
                                </div>
                            </Tab.Container>
                        </div>
                        {/* Modals */}
                        {/* Info modal */}
                        <Modal
                            show={this.state.infoModal}
                            onHide={null}
                            backdrop={'static'}
                            keyboard={false}
                        >
                            <Modal.Header>
                                <Modal.Title><CustomTranslation value={'KEY.Info'}/></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>
                                    <CustomTranslation
                                        value={'KEY.Fill in the requested information per topic per category to make optimal use of the CSC app.'}/>
                                </p>
                                <p>
                                    <CustomTranslation
                                        value={'KEY.Serve for a full app view following categories are supplemented:'}/>
                                </p>
                                <p>
                                    <ul
                                        style={{
                                            listStyleType: 'none'
                                        }}
                                    >
                                        <li>
                                            <i className={'las la-chevron-circle-right'} /> <CustomTranslation value={'KEY.Edit construction site'} />
                                        </li>
                                        <li>
                                            <i className={'las la-chevron-circle-right'} /> <CustomTranslation value={'KEY.First aid kits'} />
                                        </li>
                                        <li>
                                            <i className={'las la-chevron-circle-right'} /> <CustomTranslation value={'KEY.Nearest hospital'} />
                                        </li>
                                        <li>
                                            <i className={'las la-plus-circle'} /> <CustomTranslation value={'KEY.The topics under settings'} />
                                        </li>
                                    </ul>
                                </p>
                                <p>
                                    <p>
                                        <CustomTranslation value={'KEY.Complete the following topics for one optimal VG plan upload:'} />
                                    </p>
                                </p>
                                <p>
                                    <ul
                                        style={{
                                            listStyleType: 'none'
                                        }}
                                    >
                                        <li>
                                            <i className={'las la-chevron-circle-right'} /> <CustomTranslation value={'KEY.Edit construction site'} />
                                        </li>
                                        <li>
                                            <i className={'las la-chevron-circle-right'} /> <CustomTranslation value={'KEY.Project Info'} />
                                        </li>
                                        <li>
                                            <i className={'las la-chevron-circle-right'} /> <CustomTranslation value={'KEY.Accessibility'} />
                                        </li>
                                        <li>
                                            <i className={'las la-chevron-circle-right'} /> <CustomTranslation value={'KEY.First aid kits'} />
                                        </li>
                                        <li>
                                            <i className={'las la-chevron-circle-right'} /> <CustomTranslation value={'KEY.Nearest hospital'} />
                                        </li>
                                        <li>
                                            <i className={'las la-plus-circle'} /> <CustomTranslation value={'KEY.The topics under settings'} />
                                        </li>
                                    </ul>
                                </p>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    block
                                    variant={'dark'}
                                    onClick={() => {
                                        this.setState({
                                            infoModal: false
                                        });
                                    }}
                                >
                                    <CustomTranslation value={'KEY.Close'} />
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        {/* Delete Modal */}
                        <CustomConfirm
                            show={this.state.deleteModal}
                            variant={'danger'}
                            title={<CustomTranslation value={'KEY.Delete'} />}
                            body={
                                <p>
                                    <CustomTranslation value={'KEY.Do you wish to delete the construction site:'} /> {this.state.data.constructionSite.name}?
                                </p>
                            }
                            cancelLabel={<CustomTranslation value={'KEY.Cancel'} />}
                            onCancel={() => this.setState({deleteModal: false})}
                            confirmLabel={<CustomTranslation value={'KEY.Delete'} />}
                            onConfirm={() => this.deleteConstructionSite()}
                        />
                    </div>
                </CustomContainer>
            );
        } else {
            return (
                <CustomContainer>
                    <Loader />
                </CustomContainer>
            );
        }
    }
}

//export
export default function (props) {
    const params = useParams();
    return <EditConstructionSiteScreen {...props} params={params} />;
}